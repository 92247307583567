import { type FC, Fragment, useEffect, useState, useRef } from 'react';
import { graphql, useFragment } from 'react-relay';
import { sessionStorage } from 'dibs-browser-storage';

/* Components */
import { SbSharedProductAdWrapper } from '../SbSharedProductAdWrapper/SbSharedProductAdWrapper';
import { SharedItemTile } from '../../shared/SharedItemTile/SharedItemTile';
import { QuickViewProvider } from 'dibs-search-product-tile/exports/QuickViewProvider';
import { FavoritesProvider } from '../../components/global/FavoriteIcons/FavoritesProvider';
import { useMeasurementUnit } from '../useMeasurementUnit';
import { useSharedUrgencySignals } from '../hooks/sharedUrgencySignals/useSharedUrgencySignals';
import { useSbSharedItemTracking } from '../hooks/sharedItemTracking/useSbSharedItemTracking';

/* Helpers */
import { locations } from '../../utils/tracking/shared/favoritesTracking';
import { useSbSelector } from '../../reducers/useSbSelector';
import { useMergedSbAndSponsoredItems } from '../hooks/useMergedSbAndSponsoredItems';

/* Constants */
import { VERTICAL_OFFSET_MOBILE_IMG } from '../../constants/imageConstants';

import { type FavoritesProviderChildrenProps } from '../../components/global/FavoriteIcons/FavoritesProviderChildrenProps';
import { type SbMobileSearchResultContainer_viewer$key } from './__generated__/SbMobileSearchResultContainer_viewer.graphql';
import { type SbMobileSearchResultContainer_itemSearch$key } from './__generated__/SbMobileSearchResultContainer_itemSearch.graphql';
import { type SbMobileSearchResultContainer_user$key } from './__generated__/SbMobileSearchResultContainer_user.graphql';
import { type SbMobileSearchResultContainer_item$key } from './__generated__/SbMobileSearchResultContainer_item.graphql';

const SWIPE_INDICATOR_SEEN = 'swipeIndicatorSeen';

const viewerFragment = graphql`
    fragment SbMobileSearchResultContainer_viewer on Viewer {
        ...SharedItemTile_viewer
        ...useSbSharedItemTracking_viewer
    }
`;
const itemSearchFragment = graphql`
    fragment SbMobileSearchResultContainer_itemSearch on ItemSearchQueryConnection {
        ...useMeasurementUnit_itemSearch
        ...useSbSharedItemTracking_itemSearch
        ...SbSharedProductAdWrapper_itemSearch
        ...SharedItemTile_itemSearch
        ...useMergedSbAndSponsoredItems_itemSearch
        displayUriRef
    }
`;
const userFragment = graphql`
    fragment SbMobileSearchResultContainer_user on User {
        serviceId
        ...useMeasurementUnit_user
    }
`;

const mergedItemsFragment = graphql`
    fragment SbMobileSearchResultContainer_item on Item @relay(plural: true) {
        serviceId
        isSold
        ...FavoritesProvider_item
        ...useSharedUrgencySignals_item
        ...useSbSharedItemTracking_item
        ...SharedItemTile_item
            @arguments(isTrade: $isTrade, priceBookName: $priceBookName, showSeller: $showSeller)
    }
`;

type Props = {
    viewer: SbMobileSearchResultContainer_viewer$key;
    itemSearch: SbMobileSearchResultContainer_itemSearch$key;
    user: SbMobileSearchResultContainer_user$key;
    showMeasurements: boolean;
};

export const SbMobileSearchResultContainer: FC<Props> = ({
    viewer: viewerRef,
    itemSearch: itemSearchRef,
    user: userRef,
    showMeasurements,
}) => {
    const viewer = useFragment(viewerFragment, viewerRef);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const user = useFragment(userFragment, userRef);
    const { displayUriRef } = itemSearch;
    const isClient = useSbSelector(state => state.relayVariables.variables.isClient);
    const { hasNewFilterUpdates, filtersShown } = useSbSelector(state => state.filters);

    const initialDisplayUriRef = useRef(displayUriRef);
    const userId = user ? user.serviceId : null;
    const [measurementUnit] = useMeasurementUnit({
        user,
        itemSearch,
    });

    /* scroll to top of search results when there are filter updates.
    the filter menu prevents the body from scrolling when it is open,
    so we have to wait until it is closed before calling window.scroll().
    hasNewFilterUpdates is set back to false before the modal is closed,
    which is why scrollToTop is needed to track whether the scroll should occur */
    const [scrollToTop, setScrollToTop] = useState(false);

    const [showSwipeIndicator, setShowSwipeIndicator] = useState(false);

    useEffect(() => {
        if (hasNewFilterUpdates) {
            setScrollToTop(true);
        }
    }, [hasNewFilterUpdates]);

    useEffect(() => {
        if (!filtersShown && scrollToTop) {
            window.scroll(0, 0);
            setScrollToTop(false);
        }
    }, [filtersShown, scrollToTop]);

    useEffect(() => {
        if (!sessionStorage.getItem(SWIPE_INDICATOR_SEEN)) {
            setShowSwipeIndicator(true);
            sessionStorage.setItem(SWIPE_INDICATOR_SEEN, true);
        }
    }, []);

    useEffect(() => {
        if (initialDisplayUriRef.current && initialDisplayUriRef.current !== displayUriRef) {
            setShowSwipeIndicator(false);
        }
    }, [displayUriRef]);

    const { mergedItems: mergedItemsRef, sponsoredItemsIndexMetadataMap } =
        useMergedSbAndSponsoredItems({ itemSearch, isMobile: true });

    const mergedItems = useFragment<SbMobileSearchResultContainer_item$key>(
        mergedItemsFragment,
        mergedItemsRef
    );

    const { fetchItemUrgencySignals, getUrgencySignal } = useSharedUrgencySignals(mergedItems);
    const { fireItemImpressionTracking, fireItemClickTracking } = useSbSharedItemTracking({
        viewer,
        itemSearch,
        items: mergedItems,
        getUrgencySignal,
    });

    return (
        <FavoritesProvider
            location={locations.SEARCH_BROWSE}
            disable={!isClient}
            userId={userId}
            items={mergedItems}
            fetchFolder={false}
        >
            {({ props: favorites }: { props: FavoritesProviderChildrenProps }) => (
                <QuickViewProvider>
                    <>
                        {mergedItems.map((item, index) => {
                            const isSponsored =
                                sponsoredItemsIndexMetadataMap &&
                                typeof sponsoredItemsIndexMetadataMap[index] !== 'undefined';
                            const itemKey = item?.serviceId
                                ? `${item?.serviceId}${isSponsored ? '_SPONSORED' : ''}`
                                : index;
                            return (
                                <Fragment key={itemKey}>
                                    <SbSharedProductAdWrapper
                                        index={index}
                                        pageCol={2}
                                        itemSearch={itemSearch}
                                    />
                                    <SharedItemTile
                                        isSponsored={isSponsored}
                                        favorites={favorites}
                                        index={index}
                                        item={item}
                                        user={null}
                                        itemSearch={itemSearch}
                                        showSellerName={false}
                                        showMeasurements={showMeasurements}
                                        beforeRender={() => fetchItemUrgencySignals(index)}
                                        onContentVisible={() =>
                                            fireItemImpressionTracking({
                                                itemId: item?.serviceId,
                                                index,
                                                isSponsored,
                                            })
                                        }
                                        onClick={() =>
                                            fireItemClickTracking({
                                                itemId: item?.serviceId,
                                                index,
                                                isSponsored,
                                            })
                                        }
                                        imageLoadVerticalOffset={VERTICAL_OFFSET_MOBILE_IMG}
                                        measurementUnit={measurementUnit}
                                        viewer={viewer}
                                        showSwipeIndicator={showSwipeIndicator && index === 0}
                                        hideRecentSoldPrice={!user && item?.isSold}
                                        urgencySignal={getUrgencySignal(item.serviceId)?.message}
                                    />
                                </Fragment>
                            );
                        })}
                    </>
                </QuickViewProvider>
            )}
        </FavoritesProvider>
    );
};
