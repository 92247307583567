/**
 * @generated SignedSource<<8e0e34b2d345707a2b3bf7ac6a788795>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileSearchResultContainer_item$data = ReadonlyArray<{
  readonly isSold: boolean | null;
  readonly serviceId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"FavoritesProvider_item" | "SharedItemTile_item" | "useSbSharedItemTracking_item" | "useSharedUrgencySignals_item">;
  readonly " $fragmentType": "SbMobileSearchResultContainer_item";
}>;
export type SbMobileSearchResultContainer_item$key = ReadonlyArray<{
  readonly " $data"?: SbMobileSearchResultContainer_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileSearchResultContainer_item">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isTrade"
    },
    {
      "kind": "RootArgument",
      "name": "priceBookName"
    },
    {
      "kind": "RootArgument",
      "name": "showSeller"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbMobileSearchResultContainer_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSold",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FavoritesProvider_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSharedUrgencySignals_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSbSharedItemTracking_item"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isTrade",
          "variableName": "isTrade"
        },
        {
          "kind": "Variable",
          "name": "priceBookName",
          "variableName": "priceBookName"
        },
        {
          "kind": "Variable",
          "name": "showSeller",
          "variableName": "showSeller"
        }
      ],
      "kind": "FragmentSpread",
      "name": "SharedItemTile_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "b4b641e764c5a2627ee61abff877fd93";

export default node;
