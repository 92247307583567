import { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import Filter from 'dibs-icons/exports/legacy/Filter';
import { Link } from 'dibs-elements/src/Link';
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';
import classnames from 'classnames';
import debounce from 'lodash.debounce';

import { getValidSharedAppliedFilters } from '../SbSharedAppliedFilters/sbSharedAppliedFiltersHelpers';
import { SbMobileSortRefineMessages as messages } from './SbMobileSortRefineMessages';

import { useIntl } from 'dibs-react-intl';
import { getQueryParam } from '../../utils/uriUtils';
import { trackEvent } from 'dibs-tracking';

import { toggleFilterDisplay } from '../../actions/filterActions';

import dibsCss from 'dibs-css';
import styles from './SbMobileSortRefine.scss';

let SbMobileSortRefineComponent = ({ dispatch, itemSearch }) => {
    const intl = useIntl();
    const topSentinelRef = useRef(null);
    const wrapperRef = useRef(null);
    const prevScroll = useRef(0);
    const [isScrollUp, setIsScrollUp] = useState(false);
    const [isHeaderSticking, setIsHeaderSticking] = useState(false);

    useEffect(() => {
        if (getQueryParam(global?.location?.search, 'modal') === 'refine') {
            dispatch(toggleFilterDisplay());
        }
    }, [dispatch]);

    const { appliedFilters, totalResults } = itemSearch;
    const filtersCount = getValidSharedAppliedFilters(appliedFilters).reduce(
        (prev, next) => next.values.length + prev,
        0
    );
    const refinedResultsCountText = filtersCount ? ` (${filtersCount})` : '';

    const scrollListener = useCallback(
        debounce(() => {
            const scrollY = window.scrollY;
            if (scrollY === prevScroll.current) {
                return;
            } else {
                setIsScrollUp(scrollY > prevScroll.current);
            }
            prevScroll.current = scrollY;
        }, 55),
        [isScrollUp, setIsScrollUp, prevScroll]
    );

    useEffect(() => {
        window.addEventListener('scroll', scrollListener);
        return () => {
            window.removeEventListener('scroll', scrollListener);
        };
    }, [scrollListener]);

    const onClick = e => {
        trackEvent({ action: 'refine link clicked', category: 'results page facet selected' }, e);
        dispatch(toggleFilterDisplay());
        if (isHeaderSticking) {
            const searchResults = document.querySelector("div[data-tn='search-results-container']");
            const header = document.getElementById('js-header');
            window.scroll({
                top:
                    (searchResults?.offsetTop || 0) + // distance search results is from top of container
                    (header?.clientHeight || 0) - // account for the height of the mobile nav
                    (wrapperRef.current?.clientHeight || 0), // as well as the height of the filter/results header (to keep it in view)
                behavior: 'smooth',
            });
        }
    };

    return (
        <>
            <div className={styles.topSentinel} ref={topSentinelRef} />
            <VisibilityTracker
                elementRef={topSentinelRef}
                onVisibilityChange={({ isVisible }) => setIsHeaderSticking(!isVisible)}
                watchAfterFirstVisible
            />
            <div
                className={classnames(styles.wrapper, {
                    [styles.stickyMode]: isHeaderSticking,
                    [styles.hideMode]: isScrollUp,
                })}
                ref={wrapperRef}
            >
                <div className={styles.resultsCount}>
                    {intl.formatMessage(messages.totalResults, {
                        resultCount: totalResults || 0,
                    })}
                </div>
                <div className={classnames(styles.cell, dibsCss.flexShrink0)}>
                    <Link className={styles.button} onClick={onClick} dataTn="refine">
                        <Filter className={styles.filterIcon} />
                        {`${intl.formatMessage(messages.sortAndRefine)}${refinedResultsCountText}`}
                    </Link>
                </div>
            </div>
        </>
    );
};

SbMobileSortRefineComponent.propTypes = {
    itemSearch: PropTypes.shape({
        appliedFilters: PropTypes.array,
        totalResults: PropTypes.number,
    }),
    dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

SbMobileSortRefineComponent = connect(null, mapDispatchToProps)(SbMobileSortRefineComponent);

export const SbMobileSortRefine = createFragmentContainer(SbMobileSortRefineComponent, {
    itemSearch: graphql`
        fragment SbMobileSortRefine_itemSearch on ItemSearchQueryConnection {
            totalResults
            appliedFilters {
                name
                canBeDismissed
                values {
                    urlLabel
                    count
                }
            }
        }
    `,
});
