import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import { ExpandableContent } from '../../components/global/ExpandableContent/ExpandableContent';
import { SbSharedStorefrontLink } from '../SbSharedStorefrontLink/SbSharedStorefrontLink';

import styles from './SbMobileContentModuleRegular.scss';

function SbMobileContentModuleRegularComponent({ browseContentModule }) {
    const { description } = browseContentModule;

    return (
        <ExpandableContent className={styles.description} collapseHeight={65}>
            <span
                dangerouslySetInnerHTML={{ __html: description }}
                data-tn="content-module-regular-description"
            />
            <SbSharedStorefrontLink browseContentModule={browseContentModule} />
        </ExpandableContent>
    );
}

SbMobileContentModuleRegularComponent.propTypes = {
    browseContentModule: PropTypes.object.isRequired,
};

export const SbMobileContentModuleRegular = createFragmentContainer(
    SbMobileContentModuleRegularComponent,
    {
        browseContentModule: graphql`
            fragment SbMobileContentModuleRegular_browseContentModule on ContentModuleRegular {
                ...SbSharedStorefrontLink_browseContentModule
                description
            }
        `,
    }
);
